<template>
    <ValidationObserver ref="formModalValidate">
        <b-row>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('faculty')">
                    <ValidationProvider name="faculty_code" rules="required" v-slot="{ valid, errors }">
                        <faculty-selectbox v-model="innerValue.faculty_code" :validateError="errors[0]" />
                    </ValidationProvider>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('program')">
                    <ValidationProvider name="program_code" rules="required" v-slot="{ valid, errors }">
                        <b-form-select v-model="innerValue.program_code" :options="programOptions"
                            :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </ValidationProvider>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('order_of_preference')">
                    <ValidationProvider name="rank" rules="required" v-slot="{ valid, errors }">
                        <b-form-select type="number" v-model="innerValue.rank" :options="['1','2','3']"
                            :state="errors[0] ? false : null"></b-form-select>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </ValidationProvider>
                </b-form-group>
            </b-col>
        </b-row>
    </ValidationObserver>
</template>
<script>
import DoubleMajorApplicationService from '@/services/DoubleMajorApplicationService'

export default {
    name: "DoubleMajorPreferenceForm",
    props: {
        value: {
            type: Object,
            default: () => { },
        },
        type: {
            type: String,
            default: "",
        }
    },
    computed: {
        innerValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
                return val
            },
        }
    },
    watch: {
        'innerValue.faculty_code': {
            async handler(val) {
                if (!val) {
                    return
                }

                const response = await DoubleMajorApplicationService.minorApplicationsPrograms(val)
                    .catch((e) => {
                        this.$toast.error(this.$t('api.' + e.data.message))
                        this.programOptions = []
                        this.innerValue.program_code = null
                    })
                if (response.data.success) {
                    this.programOptions = response.data.data.map(item => ({ text: `${this.getLocaleText(item, 'program_name')} / ${this.$t('quota')}-${item.quota}`, value: item.program_code }))
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            programOptions: []
        }
    }
}
</script>
<style lang="">
    
</style>